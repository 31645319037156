@import url(https://fonts.googleapis.com/css?family=Roboto:wght@900&display=swap);
body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
  touch-action: manipulation;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

#app {
  display: flex;
  justify-content: center;
  height: 85vh;
}

.mauto {
  margin: auto;
}

.flex1 {
  flex: 1 1;
}

.flex5 {
  flex: 5 1;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.center-center {
  align-items: center;
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.my5 {
  margin: 5px 0px;
}

.mt-10px {
  margin-top: -10px
}

.mb-10px {
  margin-top: -10px
}

.pointer {
  cursor: pointer;
}

.h100p {
  height: 100%;
}

.block{
  display: block;
}

.center{
  text-align: center;
}

